<template>
  <div class="synergy-file">
    <div id="box" ref="box">
      <div id="placeholder"></div>
    </div>
    <remote-js :src="CX_Api" />
  </div>
</template>

<script>
import { viewInitEditor } from '@/Utils/viewonlineEdit2'
import { getToken } from '@/Utils/token'
export default {
  name: "synergyFile",
  props: ["info"],
  components: {
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: '' },
        });
      },
    },
  },
  data() {
    return {
      isAppointment:false,//预约协作弹窗
    }
  },
  created() {
    // console.log(this.info);
  },
  mounted() {
  },
  methods: {
    init(info) {
      console.log(info);
      let fileType = info.fileName[info.fileName.length - 1];
      setTimeout(() => {
        // 'http://apitest.ilaas.cn' + 
      let url = info.fileUrl
      let el = document.getElementById('box')
      el.innerHTML = '<div id="placeholder"></div>';
        viewInitEditor(
          info.nickName,
          fileType,
          url,
          // "https://u2.ai-hrcompliance.com/content/upload/files/13674917716/438dd6ed55bd45dcb49da26dd73a8349-401.doc",
          true,
          true,
          true,
          true,
          info.uniqueNo,
          "eide",
          "desktop|mobile",
          {
            comment: true,
            download: false,
            edit: true,
            print: false,
            review: true,
          },
          info.name,
          getToken(),
          // 100
        );
      },500);

      // this.isShowEdit = true;
      //    setTimeout(() => {
      //     viewInitEditor(
      //       'aa',
      //       info.fileUrl,
      //       true,
      //       true,
      //       true,
      //       true,
      //       info.uniqueNo,
      //       "view",
      //       "desktop|mobile",
      //       {
      //         comment: true,
      //         download: false,
      //         edit: true,
      //         print: false,
      //         review: true,
      //       },
      //       info.name,
      //       getToken()
      //     );
      //   }, 500);
    },
    //在线编辑
    edit(name, code,url, title,type) {
      this.isShowEdit = true;
      if(type == 'view'){
        viewInitEditor
         setTimeout(() => {
          viewInitEditor(
            name,
            url,
            true,
            true,
            true,
            true,
            code,
            type,
            "desktop|mobile",
            {
              comment: true,
              download: false,
              edit: true,
              print: false,
              review: true,
            },
            title,
            getToken()
          );
        }, 500);
      }else{
        setTimeout(() => {
          initEditor(
            name,
            url,
            true,
            true,
            true,
            true,
            code,
            "edit",
            "desktop|mobile",
            {
              comment: true,
              download: false,
              edit: true,
              print: false,
              review: true,
            },
            title,
            getToken()
          );
        }, 500);
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .synergy-file {
    // position: absolute;
    // top: 10vh;
    // left: 5vw;
    width: 82%;
    height: 100%;
    #box {
      width: 100%;
      height: 100%;
    }
  }
</style>

