function viewInitEditor (username, fileType, docUrl,
  titlebarFlag = true, rightPanelFlag = true,
  leftPanelFlag = true, toobarFlag = true,
  docKey, mode, type,
  permission, title, token, zoom) {
  console.log(permission)
  // mode for editor
  window.mode = mode
  // mode = window.mode;
  // mode for editor
  window.type = window.type || type || 'desktop'
  type = window.type
  // url for document
  // window.docUrl = document.getElementById("documentUrl").value;
  window.docUrl = docUrl
  // key for chaching and collaborate editing
  window.docKey = docKey

  // type for document
  console.log(permission)
  var docType = docUrl.substring(docUrl.lastIndexOf('.') + 1).trim().toLowerCase()
  // type for editor
  var documentType = getDocumentType(docType)

  window.permission = permission
  window.user = username

  window.user = window.user || '测试'
  var id = window.user + '-4'
  // CXO_API.CXEditor
  // creating object editing
  var onDocumentReady = function () {
    // editor.destroyEditor();

  }
  var onAppReady = function () {

  }
  console.log(fileType)
  var editor = new CXO_API.CXEditor('placeholder', {
    type: type,
    width: (type == 'desktop' ? '920px' : undefined),
    height: (type == 'desktop' ? '517px' : undefined),
    documentType,
    token,
    document: {
      title,
      url: docUrl,
      fileType: fileType,
      key: docKey,
      permissions: window.permission
    },
    editorConfig: {
      // callbackUrl: "https://www.ai-hrcompliance.com/api/changxie/callback.aspx",
      callbackUrl: 'https://api.ilaas.cn/api/callback/changxie',
      mode,
      user: {
        id: id,
        name: window.user
      },
      customization: {
        about: false,
        chat: true,
        comments: true,
        zoom: zoom || 150,
        leftMenu: leftPanelFlag,
        rightMenu: rightPanelFlag,
        toolbar: toobarFlag,
        displayTitle: titlebarFlag,
        header: true,
        statusBar: true,
        autosave: true,
        forcesave: true
      },
      sharingSettingsUrl: 'https://lcb.ai-hrcompliance.com/addressbookcs/' + docKey
    },
    events: {
      onDocumentReady: onDocumentReady,
      onAppReady: onAppReady
    }
  })
}
function key (k) {
  // var result = k.replace(new RegExp("[^0-9-.a-zA-Z_=]", "g"), "_") + (new Date()).getTime();
  var result = k.replace(new RegExp('[^0-9-.a-zA-Z_=]', 'g'), '_')
  return result.substring(result.length - Math.min(result.length, 20))
}
var getDocumentType = function (ext) {
  if ('.doc.docx.docm.dot.dotx.dotm.odt.fodt.ott.rtf.txt.html.htm.mht.pdf.djvu.fb2.epub.xps'.indexOf(ext) != -1) return 'text'
  if ('.xls.xlsx.xlsm.xlt.xltx.xltm.ods.fods.ots.csv'.indexOf(ext) != -1) return 'spreadsheet'
  if ('.pps.ppsx.ppsm.ppt.pptx.pptm.pot.potx.potm.odp.fodp.otp'.indexOf(ext) != -1) return 'presentation'
  return null
}

export {
  viewInitEditor
}
