<template>
  <div class="conference-record">
    <!-- <div class="title-box">
      <div class="title">
        <p class="p1">{{ conferenceInfo.name }}</p>
        <p class="p2">会议目标：{{ conferenceInfo.content }}</p>
      </div>
      <div class="time">
        <span class="span1">会议用时</span>
        <span class="span2">00:40:24</span>
      </div>
    </div> -->
    <div class="content-box">
        <div class="left-side" v-show="labelVal == '会议记录'">
          <p class="title">议程</p>
          <div class="left-cards-box">
            <p v-for="(item3,index3) in agendaList" :key="item3.uid" :title="`${index3 + 1} ${item3.issue.title}`">
              <template v-if="!item3.submit">
                {{index3 + 1}}.
                {{item3.issue.title}}
              </template>
            </p>
          </div>
        </div>
        <div class="center" v-show="labelVal == '会议记录'">
          <div class="center-cards-box">
            <div
              class="card"
              v-for="(item2, index2) in agendaList"
              :key="item2.user"
            >
              <div class="head-box">
                <p>
                  <span v-if="!item2.issuesubmit">
                    {{ index2 + 1 }}.
                  </span>
                  <el-input v-model="item2.issue.title" @blur="addConference(item2,index2)" placeholder="请输入议题标题..."></el-input>
                  <img class="del-icon" src="@/assets/images/iLaaSRoom/icon2.png" @click="delTopic(item2)" alt="">
                </p>
              </div>
              <div class="centent-box">
                <p class="label">议题内容：</p>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  placeholder="请输入议题内容..."
                  v-model="item2.issue.content"
                  @blur="addConference(item2,index2)"
                >
                </el-input>
              </div>
              <div class="centent-box">
                <p class="label">议题总结：</p>
                <!-- <div class="val-box">
                  {{ item2.summarize }}
                </div> -->
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  placeholder="请输入议题总结..."
                  v-model="item2.issue.summarize"
                  @blur="addConference(item2,index2)"
                >
                </el-input>
              </div>
              <div class="foot">
                <p v-if="item2.issue.user" :title="item2.issue.user">
                  由用户&nbsp;
                  <span>
                    {{ item2.issue.user }} 
                  </span>
                  &nbsp;创建</p>
              </div>
            </div>
          </div>
          <button class="btn" @click="addThesis">
            <img src="@/assets/images/index/icon-1.png" alt="" />
            <span>添加议题</span>
          </button>
        </div>
        <synergyFile v-if="cooperationStatus" ref="synergyFile" v-show="labelVal == '文件编辑'" :info="cooperationInfo"></synergyFile>
        <!-- <rightMember
          :userList="userList"
          :userTotal="userTotal"
          :cameraStatus="cameraStatus"
          :userInfo="userInfo"
        ></rightMember> -->
      <div class="right-side">
        <!-- <div class="names-box">
          <p>
            {{conferenceInfo.nickName}} &nbsp;
            <span v-for="item in userList" :key="item.nickName">
              {{item.nickName}} &nbsp;
            </span>
          </p>
          <button>收起</button>
        </div> -->
        <p class="title">当前在线人数： {{ userTotal }} 人</p>
        <div class="videos">
          <div class="video1-box">
            <video
              v-show="cameraStatus"
              class="video1"
              autoplay
              :class="{ mirrorMode: mirrorModeState }"
              id="localVideo"
            ></video>
            <img class="video1 video1-img" v-show="!cameraStatus" src="@/assets/images/iLaaSRoom/bg1.png" alt srcset />
            <p class="name">
              <img class="tx-img" src="@/assets/images/iLaaSRoom/icon1.png" alt="">
              <img v-if="!microphoneStatus" src="@/assets/images/iLaaSRoom/yl-1.png" alt="">
              <img class="yy-img" v-else src="@/assets/images/iLaaSRoom/yl-2.png" alt="">
              <span :title="userInfo.nickName">
                {{ userInfo.nickName }}
              </span>
            </p>
          </div>
          <hvideo
            class="video1"
            v-for="(v,i) in userList"
            :key="v.hvideo"
            :userInfo="v"
            :audioInfo="audioList[i]"
          ></hvideo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import synergyFile from './synergyFile.vue'
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"; //  SignalR 长链接
import RtcEngine from "./utils/rtc-engine";
import Util from "./utils/utils";
import hvideo from "./utils/hvideo";
import { mapGetters } from "vuex";
import { getToken } from "@/Utils/token";
import {v4} from 'uuid'
export default {
  name: "conferenceRecord",
  components: {
    synergyFile,
    hvideo,
  },
  data() {
    return {
      labelVal: '会议记录', // 
      connection: {}, // SignalR 实例
      errorMessage: '', // SignalR 请求错误信息
      memberList: [], // 议题数组
      agendaList: [],
      userList: null, // 会议室连麦成员
      cameraStatus: true, //摄像头状态
      audioList: [], // 会议室成员音频音量数组
      userVoiceList: [], // 会议室正在说话的成员
      userTotal: 1, //会议室在线人数
      cooperationInfo: null, // 协同数据
      microphoneStatus: true, // 麦克风状态
      cooperationStatus: true, // 协同关闭状态
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    mirrorModeState() {
      return true;
    },
    ...mapGetters({
      userInfo: 'index/userInfoState',
      browserInfo: "index/browserInfoState", // 用户浏览器
      conferenceInfo: "index/conferenceInfoState", // 会议数据
    }),
  },
  methods: {
    async init() {
      console.log(this.userInfo);
      let that = this;
      this.connection = new HubConnectionBuilder()
        .withUrl("/apis/roomHub", {
          accessTokenFactory: () => getToken(),
        })
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect([5000,5000])
        .build();
      // 实时数据通信
      this.connection.on("ReceiveIssue", (response) => {
        console.log(response);
        let newObj = response;
        if (newObj instanceof Array) {
          // console.log('数组');
          that.agendaList = newObj;
        } else {
          // newObj = JSON.parse(response);
          let _findIndex = that.agendaList.findIndex(item=>{
            return item.uid.includes(newObj.uid);
          })
          if (_findIndex === -1) {
            that.agendaList.push(newObj)
          } else {
            that.agendaList.splice(_findIndex,1,newObj)
          }
        }
        // console.log(that.agendaList);
      });
      // 开始会议 （主持人提前开始和会议开始时间到自动开始）
      this.connection.on("ReceiveStatusNotify", (res) => {
        if (res == 1) {
          that.$parent.roomStatus = '结 束 会 议';
        }
      });
      // 删除议题
      this.connection.on("DeleteIssue", (uid) => {
        let _findIndex = that.agendaList.findIndex(item=>{
            return item.uid.includes(uid);
          })
          if (_findIndex > -1) {
          that.agendaList.splice(_findIndex,1)
          }
      });
      // 会议开始
      this.connection.on("ReceiveStatusNotify", (res) => {
        if (res == 1) {
          that.$parent.roomStatus = '结 束 会 议';
        }
      });
      // 退出会议室
      this.connection.on("Quit", (res) => {
        // console.log(res);
        // this.$message.warning("您的账号被挤出会议室！");
        this.$parent.quitRoom();
      });
      // 错误捕获
      this.connection.on("ReceiveErrorMessage", (response) => {
        // console.log('错误',response);
        this.$message.error(response);
      });
      await this.connection.start();
      // console.log(id);
      this.connection.invoke("JoinToRoom", this.conferenceInfo.roomNumber); // 加入 SignalR 房间
      this.getUserInfo();
      // console.log(this.agendaList);
    },
    // 提交议题
    addConference(row,idx) {
      // console.log(this.userInfo);
      let newRow = JSON.parse(JSON.stringify(row));
      console.log(newRow);
      if (!this.$.trim(newRow.issue.title) && !this.$.trim(newRow.issue.content) && !this.$.trim(newRow.issue.summarize)) {
        return;
      }
      this.connection.invoke("UpdateIssue", this.conferenceInfo.roomNumber,JSON.stringify(newRow));
    },
    // 添加议题
    addThesis() {
      let newObj = {
        uid: v4(),
        issue: {
          title: '',
          content: '',
          summarize: '',
          creator: this.userInfo.userId,
          user: this.userInfo.nickName,
        },
      }
      this.connection.invoke("UpdateIssue", this.conferenceInfo.roomNumber,JSON.stringify(newObj));
      // this.agendaList.push(newObj);
    },
    // 删除议题
    delTopic(row) {
      // this.$confirm(`请确认是否删除议题！`)
      //   .then(() => {
        this.connection.invoke("DeleteIssue",this.conferenceInfo.roomNumber,row.uid).catch((err) => {
            // console.log(err);
          })
        // })
        // .catch((_) => {});
    },
    // 开启录制
    openRecording(type) {
      // console.log(this.conferenceInfo.roomNumber,type);
      this.connection.invoke("TranscribeNotify",this.conferenceInfo.roomNumber,type);
    },
    // 踢会议室成员
    kickOut(id) {
      this.connection.invoke("RemoveOne",this.conferenceInfo.roomNumber,Number(id));
    },
    // 退出会议室
    quitRoom() {
      this.connection.invoke("LeaveToRoom",this.conferenceInfo.roomNumber).catch((err) => {
        console.log(err);
      });
      // 退出阿里云视频
      RtcEngine.instance.logout();
    },
    // 关闭会议室
    CloseRoom() {
      this.connection.invoke("CloseRoom",this.conferenceInfo.roomNumber);
    },
    // 开始会议
    Start() {
      this.connection.invoke("Start",this.conferenceInfo.roomNumber);
    },
    // 发起文件协作
    async startSynergy(info) {
      if (info == '结束协作') {
        this.cooperationStatus = false;
        this.labelVal = '会议记录';
        this.$parent.labelVal = '会议记录';
        setTimeout(()=>{
          this.cooperationStatus = true;
        },500)
        this.connection.invoke("RemindSynergy",this.conferenceInfo.roomNumber,JSON.stringify(info));
        return
      }
      console.log(this.conferenceInfo.roomNumber,info);
      let res = await this.connection.invoke("RemindSynergy",this.conferenceInfo.roomNumber,JSON.stringify(info));
      console.log(res);
      info.nickName = this.userInfo.nickName;
      this.cooperationInfo = info;
      this.labelVal = '文件编辑';
      this.$nextTick(()=> {
          this.$refs.synergyFile.init(info);
        })
    },
    // 获取用户数据
    getUserInfo() {
      this.$nextTick(() => {
        // 开启本地预览
        RtcEngine.instance.startPreview(document.getElementById("localVideo"));
        // 开启自动订阅、推送
        RtcEngine.instance.setAutoPublishSubscribe(this.conferenceInfo.roomNumber, true, true);
        this.registerCallBack();
        RtcEngine.instance
          .login(this.conferenceInfo.roomNumber, this.conferenceInfo.creatorName, this.userInfo.userId)
          .then((res) => {
          });
      });
    },
    // 注册回调
    registerCallBack() {
      let that = this;
      RtcEngine.instance.registerCallBack(
        this.conferenceInfo.roomNumber,
        (channel, eventName, data) => {
          // console.log(eventName);
          if (eventName == "onJoin") {
            this.updateUserList(channel);
          } else if (eventName == "onPublisher") {
            this.updateUserList(channel);
          } else if (eventName == "onSubscribeResult") {
            RtcEngine.instance.setDisplayRemoteVideo(
              channel,
              data.userId,
              document.getElementById(data.userId),
              data.code
            );
          } else if (eventName == "onUserAudioMuted") {
            this.updateUserList(channel);
          } else if (eventName == "onUserVideoMuted") {
            this.updateUserList(channel);
          } else if (eventName == "onUnPublisher") {
            this.updateUserList(channel);
          } else if (eventName == "onLeave") {
            this.updateUserList(channel);
          } else if (eventName == "onBye") {
            this.$router.push("/");
          } else if (eventName == "onError") {
            Util.showErrorMsg(data);
          } else if (eventName == "onAudioLevel") {
            // console.log(data);
            that.audioList = data;
            // that.userVoiceList = data.filter(item => {
            //   return item.level > 19;
            // })
            // let list = [];
            // data.forEach((element) => {
            //   if (element.level >= 60) {
            //     list.push(element.userId);
            //   }
            // });
          }
        }
      );
    },
    registerCallBack2(channel) {
      RtcEngine.instance.registerCallBack(channel, (room, eventName, data) => {
        // console.log(eventName);
        if (
          eventName == "onJoin" ||
          eventName == "onPublisher" ||
          eventName == "onUnPublisher" ||
          eventName == "onLeave" ||
          eventName == "onUserVideoMuted"
        ) {
          this.updateUserList(channel);
        } else if (eventName == "onSubscribeResult") {
          console.log(data);
          this.setDisplayRemoteVideo(channel, data);
        } else if (eventName == "onBye") {
          this.$router.push("/");
        } else if (eventName == "onError") {
          Util.showErrorMsg(data);
        } else if (eventName == "onAudioLevel") {
          let list = [];
          data.forEach((element) => {
            if (element.level >= 60) {
              list.push(element.userId);
            }
          });
        }
      });
    },
    // 更新小组用户列表
    updateUserList() {
      this.userList = RtcEngine.instance.getUserList(this.conferenceInfo.roomNumber);
      this.userTotal = 1;
      this.userTotal += this.userList.length;
      // console.log(this.userList);
    },
    // 显示远端视频
    setDisplayRemoteVideo(channel, data) {
      RtcEngine.instance.setDisplayRemoteVideo(
        channel,
        data.userId,
        document.getElementById(data.userId),
        data.code
      );
      // }
    },
    // 关闭摄像头
    closeCamera(status) {
      this.cameraStatus = status;
      if (status) {
        RtcEngine.instance.startPublish(this.conferenceInfo.roomNumber);
      } else {
        RtcEngine.instance.stopPublish(this.conferenceInfo.roomNumber);
      }
    },
    // 关闭麦克风
    closeMicrophone(status) {
      this.microphoneStatus = status;
      if (status) {
        RtcEngine.instance.startPublish2(this.conferenceInfo.roomNumber);
      } else {
        RtcEngine.instance.stopPublish2(this.conferenceInfo.roomNumber);
      }
    },
    // 切换table栏
    clickLabel(val) {
      console.log(val);
      this.labelVal = val;
    },
  },
  // beforeDestroy() {
  //   this.quitRoom();
  // },
};
</script>

<style lang="less" scoped>
.conference-record {
  display: flex;
  flex-direction: column;
  height: 80%;
  font-size: 14px;
  padding: 0 0 10px;
  // .title-box {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   height: 8%;
  //   background: #eaf5ff;
  //   border-radius: 4px;
  //   border-left: 4px solid #6e91d8;
  //   padding: 0 16px;
  //   .title {
  //     // margin-left: 10px;
  //     .p1 {
  //       font-weight: 600;
  //       color: #333;
  //     }
  //     .p2 {
  //       font-size: 12px;
  //       color: #909399;
  //     }
  //   }
  //   .time {
  //     .span1 {
  //       font-size: 12px;
  //       color: #909399;
  //     }
  //     .span2 {
  //       font-weight: 600;
  //       color: #333;
  //       margin-left: 12px;
  //     }
  //   }
  // }
  .content-box {
    display: flex;
    justify-content: space-between;
    height: 90%;
    margin: 24px 0;
    .left-side {
      display: flex;
      flex-direction: column;
      width: 21%;
      height: 100%;
      border-radius: 18px;
      border: 1px solid #dee2e6;
      padding: 16px;
      box-sizing: border-box;
      .title {
        font-weight: 600;
        color: #333;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;
      }
      .left-cards-box {
        flex: 1;
        overflow-y: auto;
        padding-top: 14px;
        p {
          color: #202124;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 60%;
      // height: 100%;
      /deep/ .center-cards-box {
        // flex: 1;
        // height: 90% !important;
        height: 92%;
        overflow-y: auto;
        .card {
          min-height: 200px;
          border-radius: 18px;
          border: 1px solid #dee2e6;
          padding: 16px;
          box-sizing: border-box;
          margin-bottom: 15px;
          .head-box {
            p {
              display: flex;
              align-items: center;
              font-weight: 600;
              color: #333;
              .el-input {
                border: none;
                .el-input__inner {
                  border: none;
                }
              }
              .del-icon {
                width: 18px;
                cursor:pointer;
              }
            }
          }
          .centent-box {
            display: flex;
            margin-top: 16px;
            padding: 0 0 10px 20px;
            border-bottom: 1px solid #dee2e6;
            .label {
              color: #dee2e6;
              white-space: nowrap;
            }
            // .val-box {
            //   color: #333;
            // }
            .el-textarea {
              .el-textarea__inner {
                padding: 0;
                border: none;
              }
            }
          }
          .foot {
            display: flex;
            justify-content: flex-end;
            margin-top: 14px;
            p {
              display: flex;
              align-items: center;
              min-width: 80px;
              height: 30px;
              border-radius: 15px;
              padding: 0 10px;
              color: #909399;
              border: 1px solid #8279f3;
              span {
                color: #8279f3;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6%;
        background: #f8f9fa;
        border-radius: 18px;
        border: 1px solid #dee2e6;
        margin-top: 10px;
        span {
          color: #909399;
          margin-left: 10px;
        }
      }
    }
    .right-side {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 16%;
      // height: 100%;
      border-radius: 10px;
      border: 1px solid #dee2e6;
      background: #393D42;
      overflow: hidden;
      .title {
        color: #fff;
        margin-top: 4px;
      }
      .names-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        p {
          font-size: 12px;
          color: #fff;
        }
        button {
          color: #393D42;
          background: #909399;
          border: none;
          border-radius: 2px;
          cursor:pointer;
        }
      }
      .videos {
        flex: 1;
        width: 95%;
        overflow-y: auto;
        padding-top: 6px;
        .video1-box {
          position: relative;
          // width: 90%;
          .video1 {
            width: 100%;
            margin-bottom: 0;
          }
          .video1-img {
            width: 100%;
            vertical-align: middle;
          }
          .name {
            position: absolute;
            bottom: 6px;
            left: 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #fff;
            padding-right: 6px;
            background-color: rgba(62,61,61,.8);
            .tx-img {
              width: 18px;
              margin: 0 4px;
            }
            img {
              width: 14px;
              margin: 0 4px;
            }
            .yy-img {
              width: 12px;
              margin: 0 5px;
            }
            span {
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .video1 {
          // width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

