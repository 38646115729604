<template>
  <div>
    <Loader />
    <Customizer @onLogo="changeLogo" @toggle="sidebarMini" @animationChange="routerAnimationChange" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SmallSidebar v-if="$route.name === 'dashboard.home-5'" :logo="SmallSidebarLogo" @toggle="sidebarMini" />
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText" @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar title="Dashboard" :sidebarGroupTitle="sidebarGroupTitle" @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item" v-nav-toggle>
              <div class="father">
                <img src="../assets/images/home/start.png" alt="开始" v-if="isplay" @click="startHandler">
                <img src="@/assets/images/home/stop.png" alt="暂停" v-else @click="endHandler">
                <ul>
                  <li>{{one}}<span>:</span></li>
                  <li>{{two}}<span>:</span></li>
                  <li>{{three}}</li>
                </ul>
                <img src="../assets/images/home/clock.png" alt="计时时间" class="clock" @click="endTime">
              </div>
            </li>
            <li class="nav-item" style="margin-left: 10px" v-nav-toggle>
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">{{ $t('nav.allNotifications') }}<small class="badge  badge-light float-right pt-1">4</small></h5>
                    </div>
                    <a href="#" class="iq-sub-card" v-for="(item, index) in notification" :key="index">
                      <div class="media align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" :src="item.image" alt="img">
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ item.name }}</h6>
                          <small class="float-right font-size-12">{{ item.date }}</small>
                          <p class="mb-0">{{ item.description.substring(0,40) + '...' }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template slot="right">
          <ul class="navbar-list">
            <li class="" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
                <img v-if="!userInfo.avatar2" src="@/assets/images/user.png" class="img-fluid rounded mr-3" alt="user">
                <img v-else :src="userInfo.avatar" class="img-fluid rounded mr-3" alt="user">
                <div class="caption">
                  <h6 class="mb-0 line-height text-white" :title="userInfo.nickName" style="font-size: 16px;">{{userInfo.nickName}}</h6>
                  <!-- <span class="font-size-12 text-white">{{ $t('nav.user.available') }}</span> -->
                </div>
              </a>
            </li>
          </ul>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <b-container fluid="" v-if="!notBookmarkRouts.includes($route.name)">
          <b-row>
            <!-- <BreadCrumb /> -->
          </b-row>
        </b-container>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view ref="videomeeting"/>
        </transition>
      </div>
      <!-- <LayoutFixRightSide v-if="$route.name === 'dashboard.home-1' " /> -->
    </div>
    <!-- <template v-if="conferenceInfo"> -->
    <template v-if="conferenceInfo">
      <transition name="el-zoom-in-top">
        <conferenceRoom v-show="showConference" class="z-index-romm"></conferenceRoom>
      </transition>
      <button class="foldBtn" @click="fold">
        {{ showConference ? '收起会议室' : '展开会议室'}}
      </button>
    </template>
    <!-- <LayoutFooter /> -->
    <serveTimeDialog v-if="showDialog" :info="dateInfo"></serveTimeDialog>
  </div>
</template>
<script>
import { core } from '../config/pluginInit'
import { Users } from '../FackApi/api/chat'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/core/loader/Loader'
import Sidebar from '../components/core/sidebars/Sidebar'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import SideBarItems from '../FackApi/json/SideBar'
import profile from '../assets/images/user/user-1.jpeg'
// import loader from '../assets/images/loader.gif'
import loader from '../assets/images/home/iconLogo.png'
import darkLoader from '../assets/images/darkMode/dark-logo.gif'
import Lottie from '../components/core/lottie/Lottie'
import Customizer from './Components/Customizer'
import LayoutFixRightSide from './Components/LayoutFixRightSide'
import WhiteLogo from '../assets/images/logo-2.png'
import SmallSidebar from '../components/core/sidebars/SmallSidebar'
import BreadCrumb from '../components/core/breadcrumbs/BreadCrumb'
// import LayoutFooter from './Components/LayoutFooter'  隐藏底部
import { getUser, getPolicyToken } from "@/FackApi/api/index";

import serveTimeDialog from '@/views/Dialog/serveTimeDialog';
import conferenceRoom from '@/views/Forms/FormVideomeeting/conferenceRoom.vue'
export default {
  name: 'VerticleLayout',
  components: {
    // LayoutFooter,
    LayoutFixRightSide,
    Customizer,
    Lottie,
    Loader,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    BreadCrumb,

    serveTimeDialog,
    conferenceRoom,
  },
  created() {
    // 初始化会议室
    localStorage.removeItem('conferenceInfo');
    this.$store.dispatch('index/setConferenceInfo', null);
  },
  mounted () {
    this.layoutSetting(this.$route.name);
    this.getUserInfo();
    this.getPolicyTokenInfo();
  },
  computed: {
    ...mapGetters({
      cartCount: 'Ecommerce/cartCountState',
      // cartItems: 'Ecommerce/cartState',
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      darkMode: 'Setting/darkModeState',
      rtlMode: 'Setting/rtlModeState',
      colors: 'Setting/colorState',
      conferenceInfo: 'index/conferenceInfoState',
    }),
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        // case 'dashboard.home-1':
        case 'dashboard.home-3':
        case 'dashboard.home-4':
        case 'dashboard.home-5':
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    }
  },
  watch: {
    $route: function (to, from) {
      this.layoutSetting(to.name)
    }
  },
  // sidebarTicket
  data () {
    return {
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      verticalMenu: SideBarItems,
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: loader,
      usersList: Users,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      message: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '13 jan' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 Jun' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 Aug' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 Sept' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '29 Sept' }
      ],
      notification: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '23 hour ago', description: 'Enjoy smart access to videos, games' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 hour ago', description: 'Google Chromecast: Enjoy a world of entertainment' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 hour ago', description: 'Dell Inspiron Laptop: Get speed and performance from' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 hour ago', description: 'Deliver your favorite playlist anywhere in your home ' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '11 hour ago', description: 'MacBook Air features up to 8GB of memory, a fifth-generation' }
      ],
      notBookmarkRouts: [
        'dashboard.home-1',
        // 'dashboard.home-2',
        // 'dashboard.home-3',
        // 'dashboard.home-4',
        // 'dashboard.home-5',
        // 'dashboard.home-6'
      ],
      isplay: true,
      flag: null,
      one : '00', // 时
      two : '00', // 分
      three : '00', // 秒
      abc : 0, // 秒的计数
      cde : 0, // 分的计数
      efg : 0, // 时的计数
      userInfo: {},
      showDialog: false,
      dateInfo: {},
      showConference: true, // 会议室展开折叠动画
    }
  },
  methods: {
    // 获取用户数据
    getUserInfo() {
      getUser().then((res) => {
        if (res.code === 0) {
          res.data.avatar2 = res.data.avatar;
          res.data.avatar = process.env.VUE_APP_BASE_API + res.data.avatar;
          this.userInfo = res.data;
          // vuex 存储
          this.$store.dispatch('index/setUserInfo', res.data);
        }
      });
    },
    // 获取阿里云oss签名
    getPolicyTokenInfo() {
      let ossInfo = { // oss参数
        expireTime: 1200000,
        uploadDir: 'project/cover/',
        EndPoint: ''
      }
      getPolicyToken(ossInfo).then((res) => {
        if (res.code === 0) {
          let data = JSON.parse(res.content);
          // vuex 存储
          this.$store.dispatch('index/setOssInfo', data);
        }
      });
    },
    // 开始计时
    startHandler(){
      this.flag = setInterval(()=>{
        if(this.three === 60 || this.three === '60'){
          this.three = '00';
          this.abc = 0;
          if(this.two === 60 || this.two === '60'){
          this.two = '00';
          this.cde = 0;
          if(this.efg+1 <= 9){
            this.efg++;
            this.one = '0' + this.efg;
          }else{
            this.efg++;
            this.one = this.efg;
          }
          }else{
          if(this.cde+1 <= 9){
            this.cde++;
            this.two = '0' + this.cde;
          }else{
            this.cde++;
            this.two = this.cde;
          }
          }
        }else{
          if(this.abc+1 <= 9){
          this.abc++;
          this.three = '0' + this.abc;
          }else{
          this.abc++;
          this.three=this.abc;
          }
        }      
      },1000)
      this.isplay = false
    },
    // 暂停计时
    endHandler(){
      this.flag = clearInterval(this.flag)
      this.isplay = true
      this.dateInfo = {
        hour: this.one,
        minute: this.two,
        second: this.three,
        abc: this.abc,
        cde: this.cde,
        efg: this.efg,
      }
      this.showDialog = true;
    },
    // 会议室调用
    endHandler2(info){
      // this.flag = clearInterval(this.flag)
      // this.isplay = true
      this.dateInfo = info;
      this.showDialog = true;
    },
    // 重新计时
    endTime() {
      if (!this.isplay) {
        this.$confirm(`计时未结束，是否重置计时器！`)
        .then((_) => {
          this.flag = clearInterval(this.flag)
          this.isplay = true;
          this.one = '00';
          this.two = '00';
          this.three = '00';
          this.abc = 0;
          this.cde = 0;
          this.efg = 0;
        })
        .catch((_) => {});
      } else {
        this.flag = clearInterval(this.flag)
        this.isplay = true;
        this.one = '00';
        this.two = '00';
        this.three = '00';
        this.abc = 0;
        this.cde = 0;
        this.efg = 0;
      }
    },
    // 刷新会议室列表
    // upiLaaSRomm() {
    //   this.$nextTick(() => {
    //     if (this.$route.name == 'form.videomeeting') {
    //     this.$refs.videomeeting.init();
    //   }
    //   })
    // },
    layoutSetting (routeName) {
      this.modeChange({ rtl: this.rtlMode, dark: this.darkMode })
      this.onlyLogo = true
      this.onlyLogoText = true
      this.sidebarGroupTitle = true
      switch (routeName) {
        case 'dashboard.home-5':
          this.onlyLogoText = true
          this.onlyLogo = false
          break
        case 'dashboard.home-6':
          this.logo = WhiteLogo
          this.onlyLogo = true
          this.onlyLogoText = false
          this.sidebarGroupTitle = false
          break
        default:
          if (this.darkMode) {
            this.logo = darkLoader
          } else {
            this.logo = loader
          }
          break
      }
    },
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      // console.log(this.$store.dispatch('Setting/miniSidebarAction'));
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    // langChange (lang) {
    //   this.langChangeState(lang)
    //   this.$i18n.locale = lang.value
    //   document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
    //   if (lang.value === 'ar') {
    //     this.rtlAdd(lang)
    //   } else {
    //     this.rtlRemove(lang)
    //   }
    // },
    routerAnimationChange (e) {
      this.animated = e
    },
    // 折叠会议室
    fold() {
      this.showConference = !this.showConference;
    },
    ...mapActions({
      removeToCart: 'Ecommerce/removeToCartAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      modeChange: 'Setting/layoutModeAction',
    }),
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<style lang="less" scoped>
.father{
  width: 202px;
  height: 40px;
  background-color: #EAF5FF;
  // margin-top: 17px;
  border-radius: 5px;
  img{
    width: 20px;
    margin: 10px 15px;
    display: inline-block;
    float: left;
    cursor:pointer;
  }
  ul{
    border-right: 1px solid #C4D2DF;
    display: inline-block;
    line-height: 40px;
    height: 40px;
    text-align: center;
    padding: 0 15px 0 0;
    float: left;
  }
}
.search-toggle {
  min-width: 150px;
  max-width: 180px;
  margin: 14px;
  line-height: 65px !important;
  min-height: 65px !important;
  .caption {
    width: 60%;
    h6 {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.content-page {
  margin-right: 0 !important;
  // margin-top: 20px !important;
}
.foldBtn {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #fff;
  width: 120px;
  height: 30px;
  border-radius: 15px !important;
  color: #333;
  border: 1px solid #0094ff;
  z-index: 9999;
}
.z-index-romm {
  z-index: 1010 !important;
}
</style>
