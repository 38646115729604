<template>
  <div class="conference-Room">
    <button class="btn-left" @click="quitRoom('退出')">退出会议室</button>
    <div class="label-box">
      <p
        v-for="item in labelList"
        :class="labelVal == item ? 'is-p' : ''"
        @click="selectLabel(item)"
        :key="item"
      >
        {{ item }}
      </p>
    </div>
    <div class="title-box">
      <div class="title">
        <p class="p1">{{ conferenceInfo.name }}</p>
        <p class="p2">会议目标：{{ conferenceInfo.content }}</p>
      </div>
      <div class="time">
        <button class="btn1" @click="invite">邀请</button>
        <span class="span1">在线时长：</span>
        <span class="span2">{{ hour }}:{{ minute }}:{{ second }}</span>
      </div>
    </div>
    <!-- v-show="labelVal == '会议记录'" -->
    <conferenceRecord ref="conferenceRecord"></conferenceRecord>
    <!-- <synergyFile ref="synergyFile" v-show="labelVal == '文件编辑'" :info="cooperationInfo"></synergyFile> -->
    <div class="foot-box">
      <div class="recording-box" v-show="recordingStatus != '录制'">
        <img src="@/assets/images/iLaaSRoom/lz3.png" alt="">
        <p>{{ recordingStatus == '暂停' ? '暂停' : '录制中'}}</p>
        <div class="right">
          <!-- <img src="@/assets/images/iLaaSRoom/lz4.png" @click="suspend" alt=""> -->
          <img src="@/assets/images/iLaaSRoom/lz5.png" @click="endTranscribe" alt="">
        </div>
      </div>
      <div class="left-box">
        <div class="img1-box">
          <img
            v-if="microphoneStatua"
            @click="closeMicrophone(false)"
            src="@/assets/images/iLaaSRoom/mkf1.png"
            alt=""
          />
          <img
            v-else
            @click="closeMicrophone(true)"
            src="@/assets/images/iLaaSRoom/mkf2.png"
            alt=""
          />
          <p>{{ microphoneStatua ? "静音" : "解除静音" }}</p>
        </div>
        <div class="img2-box">
          <img
            v-if="cameraStatus"
            @click="closeCamera(false)"
            src="@/assets/images/iLaaSRoom/sp1.png"
            alt=""
          />
          <img
            v-else
            @click="closeCamera(true)"
            src="@/assets/images/iLaaSRoom/sp2.png"
            alt=""
          />
          <p>{{ cameraStatus ? "关闭摄像头" : "开启摄像头" }}</p>
        </div>
        <div
          class="transcribe-box"
          @mouseenter="onMouseOver"
          @mouseleave="onMouseOut"
        >
          <!-- <img :src="`@/assets/images/iLaaSRoom/${showTranscribeList ? 'lz2':'lz1'}.png`" alt=""> -->
          <img
            v-if="recordingStatus == '录制中'"
            src="@/assets/images/iLaaSRoom/lz2.png"
            alt=""
          />
          <img v-else src="@/assets/images/iLaaSRoom/lz1.png" alt="" />
          <p>{{ recordingStatus }}</p>
          <!-- v-if="showTranscribeList" -->
          <div class="transcr-box" v-if="showTranscribeList">
            <div class="centent-box">
              <p
                @click="selectTranscribeType(1)"
                :class="transcribeType == '视频录制' ? 'is-p' : ''"
              >
                视频录制
              </p>
              <p
                @click="selectTranscribeType(2)"
                :class="transcribeType == '音频录制' ? 'is-p' : ''"
              >
                音频录制
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="select-box">
        <p class="is-file-name" v-if="fileId">选择协同的文件：{{ fileInfo.name }}</p>
        <div class="fold-btn" @click="selectFold">
          <span>{{ foldStatus ? "收起文件列表" : "展开文件列表" }}</span>
          <i
            :class="`${foldStatus ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"
          ></i>
        </div>
        <button class="synergy-btn" @click="startSynergy">{{cooperationStatus}}</button>
        <div class="file-box" v-if="foldStatus">
          <div class="title">
            文件协同：目前仅支持 Word 类型文档，其他类型后续支持
            <!-- <div class="add-file-box">
              <input
                v-show="false"
                class="inputFile"
                type="file"
                ref="file"
                @change="outClick"
              />
              <p @click="addFile">+ 选择文件</p>
            </div> -->
            <el-upload
              v-show="clientVal"
              class="add-file-box"
              :action="objData.host"
              :data="objData"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
            <!-- <img src="@/assets/images/xmgl/wdkh-icon.png" alt="" /> -->
            <p>+ 选择文件</p>
          </el-upload>
          </div>
          <div class="selects">
            <p>选择客户类型：</p>
            <el-select
              v-model="typeVal"
              filterable
              @change="selectType"
              placeholder="请选择客户类型"
            >
              <el-option
                v-for="item in clientType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p>选择客户：</p>
            <el-select
              v-model="clientVal"
              filterable
              @change="selectClient"
              placeholder="请选择客户"
            >
              <el-option
                v-for="item in clientList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="cards-box">
            <div :class="`card ${fileId == item4.id ? 'is-card' : ''}`" v-for="item4 in fileList" :key="item4.id" @click="selectFile(item4)">
              <img src="@/assets/images/iLaaSRoom/word.png" alt="" />
              <div class="right">
                <p :title="item4.name">{{ item4.name }}</p>
                <p v-if="fileId == item4.id" class="is-p-2">协作中</p>
                <p v-else class="p-2">协作编辑</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button @click="selectRoomStatus">{{ roomStatus }}</button>
      </div>
    </div>
    <div class="dialog" v-if="dialogVisible">
      <div class="dialog-content-box">
        <p class="title">结束会议</p>
        <div class="center-box">
          <p class="val-p">结束会议将关闭会议室成员文件协同编辑、议题编辑，并移出会议室</p>
          <p class="val-p">确认是否关闭会议室？</p>
          <div class="foot-btn-box">
            <button @click="dialogVisible = false">取 消</button>
            <button class="btn2" @click="closeDialog">确 认</button>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog" v-if="roomLinDialog">
      <div class="dialog-content-box">
        <p class="title">iLaaS Room 链接地址</p>
        <div class="center-box">
          <p @click="linkUrl" class="link-p">{{this.roomLinInfo}}</p>
          <div class="btns-box">
            <button class="btn" @click="cancel">关 闭</button>
            <button class="btn2" @click="linkUrl">复 制</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <setRoomLink v-if="roomLinDialog" :info="roomLinInfo"></setRoomLink> -->
  </div>
</template>

<script>
import conferenceRecord from "./conferenceRecord.vue";
import setRoomLink from '@/views/Dialog/setRoomLink'
import synergyFile from './synergyFile.vue'
import RtcEngine from "./utils/rtc-engine";
import { getCustomer, getCustomerDetail } from "@/FackApi/api/project.js";
import { clientInit } from "./utils/client.js";
import { getPolicyToken, addCustomerFile, getFileUrl } from "@/FackApi/api/index.js";
import { roomInitRecord, StartRecord, StopRecord, StartConference, EndConference, uploadConferenceFile } from "@/FackApi/api/iLaaSRoom.js";
import { mapGetters } from 'vuex'
const Core = require("@alicloud/pop-core");
export default {
  name: "conferenceRoom",
  // inject: ['reload'],
  components: {
    conferenceRecord,
    synergyFile,
    setRoomLink,
  },
  data() {
    return {
      foldStatus: false,
      labelList: ["会议记录", "文件编辑"],
      labelVal: "会议记录",
      roomStatus: '开 始 会 议', // 会议状态
      cameraStatus: true, //摄像头状态
      microphoneStatua: true, // 麦克风状态
      showTranscribeList: false, // 显示悬浮框
      transcribeType: "", // 录制类型
      templateId: '', // 录制模板Id
      taskId: '', // 录制任务id
      recordingStatus: '录制', // 录制状态
      clientType: [
        // 客户类型
        { label: "个人", value: 1 },
        { label: "企业", value: 2 },
      ],
      typeVal: 1, // 选择的客户类型
      clientList: [], // 客户列表
      clientVal: "", // 选中的客户
      clientInfo: null, // 选择的客户数据
      foldStatus: false, // 文件盒子折叠状态
      fileList: [], // 客户文件列表
      fileInfo: {}, // 客户选择文件
      fileId: '', // 选择的客户文件id
      ossInfo: { // oss参数
        expireTime: 120,
        uploadDir: 'project/client/file/',
      },
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      filePathList: [], // 文件上传成功的路径
      upFileList: [], // 上传的文件
      hour: "00",
      minute: "00",
      second: "00",
      hourNum: 0, // 秒的计数
      minuteNum: 0, // 分的计数
      secondNum: 0, // 时的计数
      isplay: false, // 计时器状态
      flag: null, // 计时器
      flag2: null,
      startConferenceTime: '', // 开始会议时间
      // cooperationInfo: null, // 协同数据
      cooperationStatus: '发起协作', // 协作状态
      dialogVisible: false, // 结束会议确认弹框
      roomLinInfo: null, // 会议邀请链接
      roomLinDialog: false,
    };
  },
  created() {
    window.rtcEngine = RtcEngine.instance;
    RtcEngine.instance.isSupport().then((re) => {
      // console.log(re);
      this.$store.dispatch("index/setNowadayBrowser", re);
    });
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: 'index/userInfoState',
      ossInfo2: 'index/ossInfoState',
      conferenceInfo: "index/conferenceInfoState", // 会议数据
    }),
  },
  mounted() {
    console.log(this.conferenceInfo,'会议室数据');
    this.startHandler();
  },
  methods: {
    // 会议室状态
    // 
    init() {
      let that = this;
      let time = new Date().getTime();
      let startTime = new Date(this.conferenceInfo.startTime).getTime();
      console.log(that.$getDate(2,time),that.$getDate(2,startTime))
      // 进入会议室的时间早于会议开始时间且会议状态为未开始会议就开启一个计时器 （可能主持人之前已经开始了会议中途退出会议室再进入）
      //  && this.roomStatus != '结 束 会 议'
      if (time < startTime) {
        this.flag2 = setInterval(()=>{
          // console.log(that.$getDate(2) == that.$getDate(2,'2022-09-28 08:04:00'));
          // console.log(that.$getDate(2,time) == that.$getDate(2,startTime));
          time += 1000;
          // 主持人已经点击开始会议按钮改变了会议状态
          if (that.roomStatus == '结 束 会 议') {
            clearInterval(that.flag2)
          }
          if (that.$getDate(2,time) == that.$getDate(2,startTime)) {
            that.startConference();
            that.roomStatus = '结 束 会 议';
            that.$notify.success({
              title: '会议时间到，会议开始！',
              message: '',
              showClose: false
            });
            clearInterval(that.flag2)
          }
        },1000)
      }
      // 获取客户
      this.selectType();
    },
    // 切换tab栏
    selectLabel(val) {
      this.labelVal = val;
      this.$refs.conferenceRecord.clickLabel(val);
    },
    // 退出会议室
    quitRoom(type) {
      this.$refs.conferenceRecord.quitRoom();
      this.$store.dispatch("index/setConferenceInfo", "");
      // console.log(this.roomStatus,'this.roomStatus');
      if (this.roomStatus == '结 束 会 议' && type != "退出") {
        this.selectTemi();
      } else {
        this.$router.go(0);
      }
      // this.$parent.upiLaaSRomm();
      // this.$router.go(0);
      // this.reload();
    },
    // 退出会议室确认弹框
    closeDialog() {
      this.roomStatus = '结 束 会 议';
      this.endConference2();
    },
    // 会议在线时间超过5分钟 结束会议时弹出服务框
    selectTemi() {
      if (Number(this.hour) > 0 || Number(this.minute) >= 5) {
        let obj = {
          hour: this.hour,
          minute: this.minute,
          second: this.second,
          abc: this.hourNum,
          cde: this.minuteNum,
          efg: this.secondNum,
        }
        this.$parent.endHandler2(obj);
      } else {
        this.$router.go(0);
      }
    },
    // 新增客户文件
    addFile() {
      this.$refs.file.value = null;
      // this.selectIdx = idx;
      document.querySelector(".inputFile").click();
    },
    // 开启计时
    startHandler(){
      this.flag = setInterval(()=>{
        if(this.second === 60 || this.second === '60'){
          this.second = '00';
          this.hourNum = 0;
          if(this.minute === 60 || this.minute === '60'){
          this.minute = '00';
          this.minuteNum = 0;
          if(this.secondNum+1 <= 9){
            this.secondNum++;
            this.hour = '0' + this.secondNum;
          }else{
            this.secondNum++;
            this.hour = this.secondNum;
          }
          }else{
          if(this.minuteNum+1 <= 9){
            this.minuteNum++;
            this.minute = '0' + this.minuteNum;
          }else{
            this.minuteNum++;
            this.minute = this.minuteNum;
          }
          }
        }else{
          if(this.hourNum+1 <= 9){
          this.hourNum++;
          this.second = '0' + this.hourNum;
          }else{
          this.hourNum++;
          this.second=this.hourNum;
          }
        }      
      },1000)
      this.isplay = false
    },
    // 改变摄像头状态
    closeCamera(status) {
      this.cameraStatus = status;
      this.$refs.conferenceRecord.closeCamera(status);
    },
    // 改变麦克风状态
    closeMicrophone(status) {
      this.microphoneStatua = status;
      this.$refs.conferenceRecord.closeMicrophone(status);
    },
    // 鼠标悬浮录制按钮
    onMouseOver() {
      this.showTranscribeList = true;
    },
    onMouseOut() {
      this.showTranscribeList = false;
    },
    // 录制类型
    async selectTranscribeType(num) {
      let params = {Format: ''};
      if (num == 1) {
        this.transcribeType = "视频录制";
        params.Format = 'mp4';
      } else {
        this.transcribeType = "音频录制";
        params.Format = 'mp3';
      }
      this.recordingStatus = '录制中';
      // console.log(params);return
      let res  = await roomInitRecord(params);
      // console.log(res);  
      if (res.code == 0) {
        this.templateId = res.content;
        if (num == 1) {
          this.recordVideo();
        } else {
          this.recordVoice();
        }
      }
    },
    // 录制视频
    async recordVideo() {
      let params = {
        StreamType: 0,
        MixMode: 1,
        RoomNumber: this.conferenceInfo.roomNumber,
        templateId: this.templateId,
      };
      let res  = await StartRecord(params);
      // console.log(res);
      if (res.code == 0) {
        this.taskId = res.content;
        this.$refs.conferenceRecord.openRecording(1);
      }
    },
    // 录制音频
    async recordVoice() {
      let params = {
        StreamType: 1,
        MixMode: 1,
        RoomNumber: this.conferenceInfo.roomNumber,
        templateId: this.templateId,
      };
      let res  = await StartRecord(params);
      // console.log(res);
      if (res.code == 0) {
        this.taskId = res.content;
        this.$refs.conferenceRecord.openRecording(2);
      }
    },
    // 暂停录制
    suspend() {
      // this.recordingStatus = !this.recordingStatus;
      if (this.recordingStatus == '录制中') {
        this.recordingStatus = '暂停'
      } else {
        this.recordingStatus = '录制中'
      }
    },
    // 结束录制
    async endTranscribe() {
      let res = await StopRecord(this.taskId);
      // console.log(res);
      if (res.code == 0) {
        this.recordingStatus = '录制';
        this.transcribeType = '';
        this.$refs.conferenceRecord.openRecording(3);
      }
    },
    // 开始结束按钮
    selectRoomStatus() {
      if (this.roomStatus == '开 始 会 议') {
        this.startConference();
      } else {
        this.dialogVisible = true;
      }
    },
    // 发起协作
    async startSynergy() {
      // console.log(this.fileInfo);return
      if (this.cooperationStatus == '结束协作') {
        this.cooperationStatus = '发起协作'
        this.$refs.conferenceRecord.startSynergy('结束协作');
        return
      }
      if (!this.fileId) {
        this.$message.warning("请选择客户文件！");
        return;
      }
      let params = {
        expireTime: 3600,
        objectName: this.fileInfo.filePath,
        // BucketName: 'ilaasroom-file',
      };
      let res = await getFileUrl(params);
        // console.log(res);return
        this.labelVal = '文件编辑';
        this.foldStatus = false;
        let info = JSON.parse(JSON.stringify(this.fileInfo));
        // this.cooperationInfo = JSON.parse(JSON.stringify(this.fileInfo));
        info.fileUrl = res;
        // this.$refs.conferenceRecord.startSynergy(info);
        let params2 = {
          conferenceId: this.conferenceInfo.id,
          fileName: this.fileInfo.name,
          filePath: this.fileInfo.filePath,
          uniqueNo: this.fileInfo.uniqueNo,
        }
        let res3 = await uploadConferenceFile(params2);
        // console.log(res3);
        if (res3.code == 0) {
          this.$refs.conferenceRecord.startSynergy(info);
          this.cooperationStatus = '结束协作';
        }
    },
    // 开始会议
    async startConference() {
      let res = await StartConference(this.conferenceInfo.roomNumber);
      console.log(res);
      if (res.code == 0) {
        this.roomStatus = '结 束 会 议';
        this.$refs.conferenceRecord.Start();
      }
    },
    // 结束会议
    // endConference() {
    //   this.$confirm('结束会议将关闭会议室成员文件协同编辑、议题编辑，并移出会议室，确认是否关闭会议室？', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //       this.roomStatus = '结束会议';
    //       this.endConference2();
    //     }).catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消关闭'
    //       });          
    //     });
    // },
    async endConference2() {
      let res = await EndConference(this.conferenceInfo.roomNumber);
      // console.log(res);
      if (res.code == 0) {
        // this.roomStatus = '';
        this.$refs.conferenceRecord.CloseRoom();
        this.quitRoom();
      }
    },
    // 选择客户类型
    async selectType() {
      this.clientList = [];
      this.clientVal = "";
      this.fileList = [];
      let params = `1/99?type=${this.typeVal}`;
      let res = await getCustomer(params);
      // console.log(res);
      if (res.code == 0) {
        let data = res.items;
        data.map((item) => {
          this.clientList.push({
            label: item.name,
            value: item.id,
          });
        });
      }
    },
    // 选择客户
    async selectClient() {
      // console.log(this.info.info);
      let res = await getCustomerDetail(this.clientVal);
      if (res.code == 0) {
        // console.log(res.item);return
        let data = res.item;
        this.clientInfo = JSON.parse(JSON.stringify(data));
        let newArr = data.files;
        this.fileList = newArr.filter((item,index) => {
          let type = item.name.split(".");
          type = type[type.length -1];
          if (type == 'docx' || type == 'doc') {
            return item
          }
        })
        // console.log(this.fileList);
        this.fileList.map(item => {
          return item.fileName = item.name.split(".");
        })
      }
    },
    // 文件盒子展开状态
    selectFold() {
      this.foldStatus = !this.foldStatus;
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      // console.log(this.userInfo);return
      let newArr = file.name.split('.');
      let fileType = newArr[newArr.length - 1];
      // console.log(fileType);
      // console.log(file,file.type);return
      let imgType = ["doc", "docx" ];
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是 Word 格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 50MB!");
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      if (res3.code == 0) {
        let data = JSON.parse(res3.content);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `project/client/file/${this.userInfo.userId}/${new Date().getTime()}-${file.name}`,
        }
        this.filePathList.push(this.objData.key);
        this.upFileList.push(
          {
            name: file.name,
            filePath: this.objData.key,
          }
        )
        this.editSubmit();
      } else {
        return
      }
    },
    // 提交文件
    async editSubmit() {
      // let params = this.clientInfo;
      // params.files = params.files.concat(this.upFileList);
      // console.log(this.upFileList);
      // console.log(params);
      let params = {
        name: this.upFileList[0].name,
        filePath: this.upFileList[0].filePath,
        fileType: 1,
        customerId: this.clientInfo.id,
      }
      // return
      let res3 = await addCustomerFile(params);
      if (res3.code == 0) {
        this.selectClient();
      }
    },
    // 选择文件
    selectFile(row) {
      console.log(row);
      this.fileId = row.id;
      this.fileInfo = row;
    },
    // 会议邀请
    invite() {
      this.roomLinInfo = this.$participantLink(this.conferenceInfo.roomNumber);
      this.roomLinDialog = true;
    },
    linkUrl() {
      // this.info.path
      let url = this.roomLinInfo;
      let copyInput = document.createElement('input');//创建input元素
      document.body.appendChild(copyInput);//向页面底部追加输入框
      copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
      copyInput.select();//选择input元素
      document.execCommand("Copy");//执行复制命令
      this.$message.success("链接已复制！");//弹出提示信息，不同组件可能存在写法不同
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove();//删除动态创建的节点
    },
    cancel() {
      this.roomLinDialog = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.flag)
    clearInterval(this.flag2)
    if (this.taskId && this.recordingStatus == '录制') {
      this.endTranscribe();
    }
  },
};
</script>

<style lang="less" scoped>
.conference-Room {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  padding: 40px 30px 0 30px;
  .btn-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    width: 120px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #0094ff;
    background-color: #fff;
    cursor: pointer;
  }
  .label-box {
    display: flex;
    border-bottom: 1px solid #dee2e6;
    p {
      width: 100px;
      font-size: 14px;
      font-weight: 600;
      color: #333;
      text-align: center;
      padding-bottom: 8px;
      border-bottom: 4px solid #fff;
      cursor: pointer;
    }
    .is-p {
      color: #837af3;
      border-bottom: 4px solid #eae4fd;
    }
  }
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8%;
    background: #eaf5ff;
    border-radius: 4px;
    border-left: 4px solid #6e91d8;
    padding: 0 16px;
    margin-top: 10px;
    .title {
      // margin-left: 10px;
      .p1 {
        font-weight: 600;
        color: #333;
      }
      .p2 {
        font-size: 12px;
        color: #909399;
      }
    }
    .time {
      .btn1 {
                width: 70px;
                height: 34px;
                color: #6474C7;
                border-radius: 8px;
                background-color: #fff;
                border: 1px solid #6474C7;
                margin-right: 10px;
              }
      .span1 {
        font-size: 12px;
        color: #909399;
      }
      .span2 {
        font-weight: 600;
        color: #333;
        margin-left: 12px;
      }
    }
  }
  .foot-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 12px;
    border-top: 2px solid rgba(152, 172, 190, 0.2);
    .recording-box {
      position: absolute;
      top: -40px;
      left: 0;
      display: flex;
      align-items: center;
      width: 160px;
      height: 30px;
      border-radius: 4px;
      background: #666;
      padding-left: 10px;
      p {
        flex: 1;
        color: #FFF;
        margin-left: 10px;
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50%;
        height: 100%;
        border-radius: 4px;
        background-color: #848484;
        padding: 0 10px;
        img {
          height: 12px;
          cursor: pointer;
        }
      }
    }
    .left-box {
      display: flex;
      align-items: center;
      img {
        // width: 20px;
        height: 22px;
        cursor: pointer;
      }
      .img1-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        margin-right: 30px;
        p {
          color: #606266;
        }
      }
      .img2-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        p {
          color: #606266;
        }
      }
      .transcribe-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 60px;
        margin-left: 30px;
        p {
          color: #606266;
        }
        .transcr-box {
          position: absolute;
          top: 20%;
          left: 50%;
          transform: translate(-50%, -100%);
          width: 90px;
          height: 100px;
          .centent-box {
            position: relative;
            width: 100%;
            height: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            border-radius: 18px;
            border: 1px solid #d7dbda;
            background-color: #fff;
            padding: 10px 0;
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 40%;
              color: #606266;
              cursor: pointer;
            }
            .is-p {
              background: #f6f6f6;
            }
          }
        }
      }
      // .el-switch {
      //   margin-left: 10px;
      // }
    }
    .select-box {
      display: flex;
      align-items: center;
      position: relative;
      .is-file-name {
        font-size: 14px;
        color: #fdd371;
      }
      p {
        margin: 0 10px;
      }
      .fold-btn {
        padding: 2px 10px;
        border-radius: 10px;
        color: #6474c7;
        border: 1px solid #6474c7;
        margin-left: 10px;
        cursor: pointer;
        i {
          margin-left: 6px;
        }
      }
      .synergy-btn {
        width: 80px;
        height: 30px;
        margin-left: 16px;
        background-color: #fff;
        color: #6474c7;
        border-radius: 10px;
        border: 1px solid #6474c7;
        cursor: pointer;
      }
      .file-box {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -106%);
        width: 800px;
        height: 400px;
        box-shadow: 0px -2px 10px 0px rgba(152, 172, 190, 0.2);
        background-color: #fff;
        padding: 20px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #606266;
          .add-file-box {
            margin-left: 10px;
            .inputFile {
              width: 2px;
              height: 2px;
              border-radius: 80px;
            }
            p {
              color: #6474c7;
            }
          }
        }
        .selects {
          display: flex;
          align-items: center;
          p {
            margin: 0;
          }
          /deep/ .el-select {
            margin: 0 10px;
            height: 30px;
            .el-input {
              height: 100%;
              .el-input__inner {
                height: 30px;
              }
            }
          }
        }
        .cards-box {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          // height: 90%;
          height: 310px;
          margin-top: 10px;
          overflow-y: auto;
          .card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: initial;
            width: 31%;
            height: 56px;
            padding: 10px;
            border: 1px solid #dee2e6;
            margin: 0 2% 10px 0;
            cursor: pointer;
            img {
              width: 32px;
              height: 36px;
            }
            .right {
              width: 80%;
              p {
                color: #606266;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .is-p-2 {
                color: #fcc647;
              }
              .p-2 {
                cursor: pointer;
                color: #6474c7;
                padding: 0 !important;
              }
            }
          }
          .is-card {
            border: 1px solid #fcc647;
          }
        }
      }
    }
    .btns-box {
      button {
        width: 120px;
        height: 40px;
        color: #fff;
        font-weight: 600;
        background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
        border-radius: 25px;
        border: none;
      }
    }
  }
  .dialog {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(66,66,66,.4);
    .dialog-content-box {
      width: 600px;
      height: 230px;
      border-radius: 16px;
      background-color: #fff;
      overflow: hidden;
      .title {
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #6474C7;
        background: #EAF5FF;
      }
      .center-box {
        height: 230px;
        padding: 30px;
        .val-p {
          font-size: 16px;
          color: #333;
          text-align: center;
          line-height: 30px;
        }
        .foot-btn-box{
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
          button {
            width: 140px;
            height: 44px;
            font-size: 16px;
            font-weight: bold;
            color: #BBB;
            background: #F1F2F1;
            border-radius: 25px;
            cursor: pointer;
            border: none;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
          }
        }
        
        .link-p {
          cursor: pointer;
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>

