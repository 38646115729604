<template>
  <div class="iq-sidebar" >
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
        <img :src="logo" title="点击返回官网" v-if="onlyLogo" class="img-fluid" @click="toiLaaSwebsite" alt="logo">
        <span v-if="onlyLogoText" title="点击返回官网" @click="toiLaaSwebsite" class="logo-val">iLaaS</span>
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <CollapseMenu :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle"/>
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import CollapseMenu from '../menus/CollapseMenu'
import { APPNAME } from '../../../config/pluginInit'
import { getToken } from '../../../Utils/token'
export default {
  name: 'Sidebar',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.home-1' }) },
    items: { type: Array },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
    logoShow: { type: Boolean, default: true },
    onlyLogo: { type: Boolean, default: false },
    onlyLogoText: { type: Boolean, default: false },
    sidebarGroupTitle: { type: Boolean, default: true }
  },
  components: {
    CollapseMenu
  },
  mounted () {
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    },
    toiLaaSwebsite() {
      let query = {
          token: getToken(),
        }
        // window.location.href = `https://192.168.3.55:8088/home?tokenInfo=${JSON.stringify(query)}`;
        this.$toiLaaS('home');
    },
  },
  data () {
    return {
      appName: APPNAME,
    }
  }
}
</script>
<style lang="less" scoped>
.iq-sidebar {
  .iq-sidebar-logo {
    .logo-val {
      font-family: Arial-BoldMT, Arial;
      font-size: 40px !important;
      font-weight: 600;
      color: #2b2d5a !important;
      text-transform: none !important;
    }
  }
}
</style>
