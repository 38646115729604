<template>
  <div
    :class="`nui-video-div ${isMuteVideo() ? '':'hivon'}`"
    @mouseenter="onMouseOver"
            @mouseleave="onMouseOut"
    :id="userInfo.userId+'Div'"  :uu="[]" >
    <img class="avatar" v-show="!isMuteVideo()" src="@/assets/images/iLaaSRoom/bg1.png" alt srcset />
    <video class="nui-video" :id="userInfo.userId" v-show="isMuteVideo()"></video>
    <div class="nui-video-footer">
      <template v-if="!showVolume()">
        <img src="@/assets/images/iLaaSRoom/yl-1.png" alt="">
      </template>
      <template v-if="showVolume() && audioInfo">
        <img v-if="audioInfo.level == 0" src="@/assets/images/iLaaSRoom/yl-0.png" alt="">
        <img v-else-if="audioInfo.level > 0 && audioInfo.level <= 20" src="@/assets/images/iLaaSRoom/yl-2.png" alt="">
        <img v-else-if="audioInfo.level > 20 && audioInfo.level <= 40" src="@/assets/images/iLaaSRoom/yl-4.png" alt="">
        <img v-else-if="audioInfo.level > 40 && audioInfo.level <= 60" src="@/assets/images/iLaaSRoom/yl-6.png" alt="">
        <img v-else-if="audioInfo.level > 60 && audioInfo.level <= 80" src="@/assets/images/iLaaSRoom/yl-8.png" alt="">
        <img v-else-if="audioInfo.level > 80" src="@/assets/images/iLaaSRoom/yl-10.png" alt="">
      </template>
      <span class="user-name" :title="userInfo.displayName">{{userInfo.displayName}}</span>
    </div>
    <button v-show="showBtn" class="btn3" @click="kickOut">请出会议室</button>
    <!-- 音量大小显示 -->
    <!-- {{audioInfo.level}} -->
  </div>
</template>

<script>
import RtcEngine from "./rtc-engine";
  export default {
    props: ["userInfo","audioInfo"],
    data() {
      return {
        showBtn: false,
      }
    },
    mounted() {
      console.log(this.userInfo);
      // RtcEngine.instance.setDisplayRemoteVideo('888888888', this.userInfo.userId, document.getElementById(this.userInfo.userId));
    },
    methods: {
      isMuteVideo() {
        if (this.userInfo&&Array.isArray(this.userInfo.streamConfigs) && this.userInfo.streamConfigs.length > 0) {
          // return true;
          let index = this.userInfo.streamConfigs.findIndex((v) => {
            return v.label == "sophon_video_camera_large" && v.state === 'active'
          })
          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      },
      showVolume() {
        if (this.userInfo&&Array.isArray(this.userInfo.streamConfigs) && this.userInfo.streamConfigs.length > 0) {
          // return true;
          let index = this.userInfo.streamConfigs.findIndex((v) => {
            return v.label == "sophon_audio" && v.state === 'active'
          })
          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      },
      onMouseOver() {
        this.showBtn = true;
      },
      onMouseOut() {
        this.showBtn = false;
      },
      // 
      kickOut() {
        // console.log(this.userInfo);
        this.$parent.kickOut(this.userInfo.userId)
      },
    }
  }
</script>

<style lang="scss">
  .nui-video-div {
    margin-bottom: vh(10);
    position: relative;
    height: vh(128);
    height: initial;
    width: vh(170.6666667);
    border: none;
    margin-top: 4px;
    // border: vh(4) solid transparent;
    .avatar {
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      vertical-align: middle;
    }
    .nui-video {
      width: 100%;
      // height: 100%;
      background-color: black;
      border: none;
    }
    .nui-video-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #fff;
      padding-right: 6px;
      background-color: rgba(62,61,61,.8);
      img {
        width: 16px;
        margin: 0 4px;
      }
      .user-name {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #FFFFFF;
        text-shadow: 0 vh(2) vh(4) rgba(0, 0, 0, 0.50);
        display: inline-block;
        // width: 100%;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .hivon{
      //  height: 116px;
       border: none;
  }
  .btn3 {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    border: none;
    background-color: rgba(62,61,61,.8);
    padding-bottom: 4px;
  }
</style>