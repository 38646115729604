const Core = require('@alicloud/pop-core');

var client = new Core({
    accessKeyId: 'j0pgmo0i',
    accessKeySecret: 'f020b61bc1b109808123d44122d79ec9',
    // securityToken: '<your-sts-token>', // use STS Token
    endpoint: 'https://rtc.aliyuncs.com',
    apiVersion: '2018-01-11'
});

export const initClient = () => {
    var params = {
        "LayoutIds": [
            2
        ],
        "AppId": "j0pgmo0i",
        "Name": "测试",
        "Formats": [
            "mp4"
        ],
        "TaskProfile": "4IN_1080P",
        "MediaEncode": 20,
        "OssBucket": "ilaasroom-file",
        "OssFilePrefix": "record/{AppId}/{ChannelId_TaskId}/{EscapedStartTime}_{EscapedEndTime}",
        "FileSplitInterval": 1800,
        "DelayStopTime": 180,
        "MnsQueue": "roomInform"
    }
    var requestOption = {
        method: 'POST',
        formatParams: false,

    };
    client.request('AddRecordTemplate', params, requestOption).then((result) => {
        console.log(JSON.stringify(result));
    }, (ex) => {
        console.log(ex);
    })
}

export const startTranscribe = () => {
    var params = {
        "ChannelId": "913617029",
        "AppId": "j0pgmo0i",
        "TaskId": "11122233",
        "TemplateId": "GOkEMm7E"
      }
      
      var requestOption = {
        method: 'POST',
        formatParams: false,
      
      };
      
      client.request('StartRecordTask', params, requestOption).then((result) => {
        console.log(JSON.stringify(result));
      }, (ex) => {
        console.log(ex);
      })
      
}

export const endTranscribe = () => {
    var params = {
        "AppId": "j0pgmo0i",
        "TaskId": "11122233"
      }
      
      var requestOption = {
        method: 'POST',
        formatParams: false,
      
      };
      
      client.request('StopRecordTask', params, requestOption).then((result) => {
        console.log(JSON.stringify(result));
      }, (ex) => {
        console.log(ex);
      })
      
}