<template>
  <div class="serve-Time-dialog">
    <el-dialog
      title="服务计时器"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          服务名称
        </p>
        <el-input
          class="ipt1"
          type="text"
          v-model="form.name"
          placeholder="请输入服务名称"
        />
      </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        开始时间
      </p>
      <el-date-picker
        v-model="form.startTime"
        @change="setStartDate"
        type="datetime"
        placeholder="选择服务开始时间">
      </el-date-picker>
    </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          服务时长
        </p>
        <!-- <el-input
          class="ipt2"
          type="text"
          v-model="form.duration"
          placeholder="请输入服务时长"
        /> -->
        <div class="ipt2">
          <el-input v-model="form.hour" @blur="test2(form.hour,'时')" placeholder="时" ></el-input>
          <span>:</span>
          <el-input v-model="form.minutes" @blur="test2(form.minutes,'分')" placeholder="分" ></el-input>
          <span>:</span>
          <el-input v-model="form.seconds" @blur="test2(form.seconds,'秒')" placeholder="秒" ></el-input>
          <!-- <span style="flex:1;"></span>
          <p>小时</p> -->
        </div>
        <ul class="navbar-nav ml-auto navbar-list">
          <li class="nav-item" v-nav-toggle>
            <div class="father">
              <img
                src="@/assets/images/home/start.png"
                alt="开始"
                v-if="isplay"
                @click="startHandler"
              />
              <img
                src="@/assets/images/home/stop.png"
                alt="暂停"
                v-else
                @click="endHandler"
              />
              <ul>
                <li>{{ hour }}<span>:</span></li>
                <li>{{ minute }}<span>:</span></li>
                <li>{{ second }}</li>
              </ul>
              <img
                src="@/assets/images/home/clock.png"
                alt="计时时间"
                class="clock"
                @click="endTime"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="row-box row-box2">
        <p class="label">
          <span>* </span>
          服务客户
        </p>
        <el-select v-model="form.customerId" @change="selectCustomer" filterable placeholder="请选择">
          <el-option
            v-for="item in clientList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <p class="btn-p" @click="addClient">快速添加客户</p>
      </div>
      <div class="row-box row-box2">
        <p class="label">
          <span>* </span>
          服务任务
        </p>
        <el-select v-model="form.assignmentId" @change="selectTask" :disabled="!form.customerId" filterable placeholder="请选择">
          <el-option
            v-for="item in unfinshedTaskList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <p :class="`btn-p ${form.customerId ? '':'btn-p2'}`" @click="addTask">快速创建任务</p>
      </div>
    <div class="row-box">
      <p class="label">
        业务类型
      </p>
      <el-input v-model="isSelectTaskInfo.businessTypeName" disabled></el-input>
    </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          服务内容
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="请输入内容"
          v-model="form.content">
        </el-input>
      </div>
    <template v-if="isSelectTaskInfo.businessTypeName == '代理案件'">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          案件节点
        </p>
        <el-select v-model="form.caseNode" filterable placeholder="请选择案件节点">
          <el-option
            v-for="item in caseNodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          是否结案
        </p>
        <el-radio-group v-model="form.isClosed">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
    </template>
    <template v-if="form.isClosed">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案结果
        </p>
        <el-select v-model="form.closedResult" filterable placeholder="请选择结案结果">
          <el-option
            v-for="item in caseResultList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案节点
        </p>
        <el-select v-model="form.closedNode" filterable placeholder="请选择结案节点">
          <el-option
            v-for="item in caseSettleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案内容
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          :disabled="false"
          placeholder="请输入结案内容"
          v-model="form.closedContent"
        >
        </el-input>
      </div>
    </template>
    <div class="row-box" v-if="isSelectTaskInfo.businessTypeName == '代理案件'">
      <p class="label">
        <span>* </span>
        审理机构
      </p>
      <el-input
        type="text"
        v-model="form.hearCaseOrganization"
        :disabled="false"
        placeholder="请输入审理案件机构"
      />
    </div>
      <div class="row-box">
        <p class="label">负责人</p>
        <el-input
          class="ipt1"
          type="text"
          v-model="form.principal"
          placeholder="请输入负责人姓名"
        />
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          是否收费
        </p>
        <el-radio-group v-model="form.isNeedCharge">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
        <el-input
          class="ipt3"
          @blur="test"
          v-if="form.isNeedCharge"
          v-model="form.univalence"
        />
        <p class="label label-cost" v-if="form.isNeedCharge">
          总价:
          <span style="color: #333;">{{totalCost}} 元</span>
        </p>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="addSubmit">保 存</button>
      </div>
    </el-dialog>
    <addClientDialog v-if="showClientDialog"></addClientDialog>
    <addTaskDialog v-if="showTaskDialog" :info="taskInfo"></addTaskDialog>
  </div>
</template>

<script>
import addTaskDialog from '@/views/Dialog/addTaskDialog.vue'
import addClientDialog from '@/views/Dialog/addClientDialog';
  import {
    getCustomerList,
    getUnfinshedTaskList,
    addServiceRecord,
  } from "@/FackApi/api/project.js";
export default {
  name: "serveTimeDialog",
  components: {
    addTaskDialog,
    addClientDialog,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      serveForm: {
        name: "",
        hour: '',
        minute: '',
        second: '',
        client: '',
        centents: '',
        task: '',
        principal: '',
        cost: '',
      },
      form: {
        name: "",
        startTime: "",
        duration: "",
        hour: "",
        minutes: "",
        seconds: "",
        customerId: "",
        content: "",
        assignmentId: "",
        principal: "",
        isNeedCharge: true,
        univalence: "",
        caseNode: "", // 案件节点
        isClosed: false, // 是否结案
        closedResult: "", // 结案结果
        closedNode: "", // 结案节点
        closedContent: "", // 结案内容
        hearCaseOrganization: "", // 审理案件机构
      },
      isplay: true,
      hour: "00",
      minute: "00",
      second: "00",
      abc : 0, // 秒的计数
      cde : 0, // 分的计数
      efg : 0, // 时的计数
      taskList: [],
      radio: 1,
      clientList: [],
      unfinshedTaskList: [],
      totalCost: 0, // 总价
      showTaskDialog: false,
      showClientDialog: false,
      taskInfo: null,
      isSelectTaskInfo: {}, // 选择项目任务
      caseNodeList: this.$getCaseNodeList(), // 案件节点
      caseResultList: this.$getCaseResultList(), // 案件结果
      caseSettleList: this.$getCaseSettleList(), // 结案节点
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.form.hour = this.info.hour;
      this.form.minutes = this.info.minute;
      this.form.seconds = this.info.second;
      this.hour = this.info.hour;
      this.minute = this.info.minute;
      this.second = this.info.second;
      this.abc = this.info.abc;
      this.cde = this.info.cde;
      this.efg = this.info.efg;
      this.getCustomerListInfo();
      if (this.showTaskDialog) {
        this.getSelectListInfo();
      }
    },
    // 获取未完成任务列表
    async getSelectListInfo() {
      let res = await getUnfinshedTaskList(`?customerId=${this.form.customerId}`);
      if (res.code == 0) {
        this.unfinshedTaskList = res.items;
      }
    },
    // 获取客户列表
    async getCustomerListInfo() {
      let res2 = await getCustomerList();
      if (res2.code == 0) {
        this.clientList = res2.items;
      }
    },
    // 选择客户
    selectCustomer() {
      this.form.assignmentId = '';
      this.form.caseNode = '';
      this.form.isClosed = false;
      this.isSelectTaskInfo = {};
      this.$nextTick(() => {
        this.getSelectListInfo();
      })
    },
    // 选择服务任务
    selectTask() {
      for (let i = 0; i < this.unfinshedTaskList.length; i++) {
        let item = this.unfinshedTaskList[i];
        if (item.id === this.form.assignmentId) {
          this.isSelectTaskInfo = item;
          break
        }
      }
    },
    // 选择开始时间
    setStartDate() {
      // console.log(this.$getDate(2,this.form.startDate));
      this.form.startTime = this.$getDate(2,this.form.startTime)
    },
    // 单价失焦
    test() {
      if(!(/^([1-9]\d*|[0]{1,1})$/.test(this.form.univalence))){
        this.$message.warning(`单价应为正整数!`);
        this.form.univalence = '';
        return
      }
      this.calculateCost()
    },
    test2(num) {
      if(!(/^([1-9]\d*|[0]{1,1})$/.test(Number(num)))){
        this.$message.warning(`时间应为正整数!`);
        this.totalCost = 0;
        if (type == '时') {
          this.form.hour = '';
        } else if (type == '分') {
          this.form.minutes = '';
        } else {
          this.form.seconds = '';
        }
        return
      }
      
      if (type == '分' && Number(num) > 59) {
        this.$message.warning(`时间分最大为59!`);
        this.form.minutes = '';
        return
      }
      if (type == '秒' && Number(num) > 59) {
        this.$message.warning(`时间秒最大为59!`);
        this.form.seconds = '';
        return
      }
      this.calculateCost()
    },
    // 计算总价
    calculateCost() {
      let hour = Number(this.form.hour);
      let minutes = Number(this.form.minutes);
      let seconds = Number(this.form.seconds);
      let hourCost = hour * this.form.univalence;
      let minuteCost = (minutes / 60) * this.form.univalence;
      let secondCost = ((seconds / 60) / 60 ) * this.form.univalence;
      this.totalCost = (hourCost + minuteCost + secondCost).toFixed(2);
    },
    // 开始计时
    startHandler(){
      this.flag = setInterval(()=>{
        if(this.second === 60 || this.second === '60'){
          this.second = '00';
          this.abc = 0;
          if(this.minute === 60 || this.minute === '60'){
          this.minute = '00';
          this.cde = 0;
          if(this.efg+1 <= 9){
            this.efg++;
            this.hour = '0' + this.efg;
          }else{
            this.efg++;
            this.hour = this.efg;
          }
          }else{
          if(this.cde+1 <= 9){
            this.cde++;
            this.minute = '0' + this.cde;
          }else{
            this.cde++;
            this.minute = this.cde;
          }
          }
        }else{
          if(this.abc+1 <= 9){
          this.abc++;
          this.second = '0' + this.abc;
          }else{
          this.abc++;
          this.second=this.abc;
          }
        }      
      },1000)
      this.isplay = false
    },
    // 暂停计时
    endHandler(){
      this.flag = clearInterval(this.flag)
      this.isplay = true
      this.form.hour = this.hour;
      this.form.minutes = this.minute;
      this.form.seconds = this.second;
      this.calculateCost()
    },
    // 重新计时
    endTime() {
      if (!this.isplay) {
        this.$confirm(`计时未结束，是否重置计时器！`)
        .then((_) => {
          this.flag = clearInterval(this.flag)
          this.isplay = true;
          this.one = '00';
          this.two = '00';
          this.three = '00';
          this.abc = 0;
          this.cde = 0;
          this.efg = 0;
        })
        .catch((_) => {});
      } else {
        this.flag = clearInterval(this.flag)
        this.isplay = true;
        this.one = '00';
        this.two = '00';
        this.three = '00';
        this.abc = 0;
        this.cde = 0;
        this.efg = 0;
      }
      this.totalCost = 0;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // done();
          this.$parent.showDialog = false;
        })
        .catch((_) => {});
    },
    // 创建任务
    async addSubmit() {
      if (!this.isplay) {
        this.$message.warning("请先暂停计时");
        return
      }
      let verifyObj = {
        name: "服务名称",
        startTime: "开始时间",
        duration: "服务时长",
        customerId: "服务客户",
        assignmentId: "服务任务",
        content: "服务内容",
      };
      if (this.form.hour || this.form.hour || this.form.hour) {
        this.form.duration = '11';
      }
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      if (this.isSelectTaskInfo.businessTypeName == "代理案件" && !this.form.caseNode) {
        this.$message.warning(`案件节点不能为空！`);
        return;
      }
      if (this.form.isClosed) {
        let verifyObj2 = {
          closedResult: "结案结果",
          closedNode: "结案节点",
          closedContent: "结案内容",
          hearCaseOrganization: "审理机构",
        };
        for (let key in verifyObj2) {
          if (!this.form[key]) {
            this.$message.warning(`${verifyObj2[key]}不能为空！`);
            return;
          }
        }
      }
      if (this.form.isNeedCharge && !this.form.univalence) {
        this.$message.warning(`服务单价不能为空！`);
        return;
      }
      let params = {
        name: this.form.name,
        startTime: this.form.startTime,
        hour: Number(this.form.hour),
        minutes: Number(this.form.minutes),
        seconds: Number(this.form.seconds),
        customerId: this.form.customerId,
        content: this.form.content,
        assignmentId: this.form.assignmentId,
        principal: this.form.principal,
        isNeedCharge: this.form.isNeedCharge,
        univalence: this.form.isNeedCharge ? Number(this.form.univalence ): 0,
        totalAmount: this.form.isNeedCharge ? this.totalCost : 0,
        caseNode: this.isSelectTaskInfo.businessTypeName == '代理案件' ? this.form.caseNode:null,  // 案件节点
        isClosed: this.isSelectTaskInfo.businessTypeName == '代理案件' ? this.form.isClosed:null,  // 是否结案
        closedResult: this.form.isClosed ? this.form.closedResult:null,  // 结案结果
        closedNode: this.form.isClosed ? this.form.closedNode:null,  // 结案节点
        closedContent: this.form.isClosed ? this.form.closedContent:null,  // 结案内容
        hearCaseOrganization: this.form.isClosed ? this.form.hearCaseOrganization:null,  // 审理案件机构
      };
      // console.log(params);return
      let res3 = await addServiceRecord(params);
      // console.log(res3);
      if (res3.code == 0) {
        // this.$parent.one = '00';
        // this.$parent.two = '00';
        // this.$parent.three = '00';
        // this.$parent.abc = 0;
        // this.$parent.cde = 0;
        // this.$parent.efg = 0;
        if (
          this.$route.path == '/home-1' ||
          this.$route.path == '/core/ui-badges'
        ) {
          this.$router.go(0);
        }
        this.cancel();
      }
    },
    cancel() {
      this.$parent.one = '00';
      this.$parent.two = '00';
      this.$parent.three = '00';
      this.$parent.abc = 0;
      this.$parent.cde = 0;
      this.$parent.efg = 0;
      // 当前路由在iLaaS 页面
      if (
        this.$route.path == '/form/form-videomeeting'
      ) {
        this.$router.go(0);
      }
      this.$parent.showDialog = false;
    },
    // 添加任务
    addTask() {
      if (!this.form.customerId) return;
      let name = '';
      for (let i = 0; i < this.clientList.length; i++) {
        let item = this.clientList[i];
        if (item.id == this.form.customerId) {
          name = item.name;
          break
        }
      }
      this.taskInfo = {
        id: this.form.customerId,
        name: name,
      }
      this.showTaskDialog = true;
    },
    // 新增客户
    addClient() {
      this.showClientDialog = true;
    },
  },
};
</script>

<style lang="less" scoped>
.serve-Time-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        line-height: 60px;
        padding: 0;
        background: #eaf5ff;
        .el-dialog__title {
          font-size: 20px;
          font-weight: bold;
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          width: 85%;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #909399;
            margin-right: 18px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            flex: 1;
            height: 40px;
            font-size: 14px;
            background: #fff;
            border-radius: 10px;
            box-sizing: border-box;
            input {
              height: 100%;
              width: 100%;
              border-radius: 10px;
            }
          }
          // .ipt1 {
          // }
          .ipt2 {
            display: flex;
            align-items: center;
            flex: 1;
            height: 40px;
            border: 1px solid #D7DBDA;
            margin-right: 20px;
            border-radius: 10px;
            padding: 0 30px 0 15px;
            .el-input {
              flex: none;
              width: 70px;
              height: 70%;
              .el-input__inner {
                width: 100%;
                height: 100%;
                border: none;
                font-size: 14px;
                color: #333;
                border-bottom: 1px solid #D7DBDA;
                border-radius: 0;
              }
            }
            span {
              font-size: 14px;
              color: #333;
            }
            p {
              font-size: 14px;
              color: #909399;
            }
          }
          // .ipt2 {
          //   position: relative;
          //   margin-right: 20px;
          //   input {
          //     height: 100%;
          //     width: 100%;
          //     padding-right: 80px;
          //   }
          // }
          // .ipt2::after{
          //   content: '小时';
          //   position: absolute;
          //   top: 0;
          //   right: 20px;
          //   color: #909399;
          //   line-height: 50px;
          // }
          .el-textarea {
            .el-textarea__inner {
              font-size: 14px;
              border-radius: 10px;
            }
          }
          .father {
            width: 202px;
            height: 40px;
            background-color: #eaf5ff;
            border-radius: 12px;
            img {
              width: 20px;
              margin: 10px 15px;
              display: inline-block;
              float: left;
              cursor: pointer;
            }
            ul {
              border-right: 1px solid #c4d2df;
              display: inline-block;
              line-height: 40px;
              height: 40px;
              text-align: center;
              padding: 0 15px 0 0;
              float: left;
            }
          }
          .el-select {
            flex: 1;
            .el-input {
              width: 100%;
              .el-input__inner {
                width: 100%;
              }
            }
          }
          .el-radio-group {
            .el-radio {
              margin-bottom: 0;
              .el-radio__label {
                font-size: 14px;
              }
            }
            .is-checked {
              .is-checked {
                .el-radio__inner {
                  border-color: #6474C7;
                  background-color: #6474C7;
                }
              }
              .el-radio__label {
                color: #6474C7;
              }
            }
          }
          .ipt3 {
            position: relative;
            flex: none;
            width: 40%;
            margin-left: 20px;
            input {
              height: 100%;
              width: 100%;
              padding: 0 90px 0 40px;
            }
          }
          .ipt3::before{
            content: '¥';
            position: absolute;
            top: 0;
            left: 20px;
            color: #909399;
            line-height: 40px;
          }
          .ipt3::after{
            content: '元/小时';
            position: absolute;
            top: 0;
            right: 20px;
            color: #909399;
            line-height: 40px;
          }
        }
  .row-box2 {
    position: relative;
    .btn-p {
      position: absolute;
      top: 50%;
      right: -120px;
      color: #76b0eb;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    .btn-p2 {
      color: #ccc;
      cursor:not-allowed;
    }
  }
        .label-cost {
          margin-left: 50px;
          font-size: 14px;
          color: #333;
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 140px;
            height: 40px;
            font-size: 16px;
            font-weight: 600;
            color: #BBB;
            border-radius: 25px;
            background: #F1F2F1;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
